import { defineStore } from "pinia";
import ProductApi from "@/api/ProductApi";
import { usePurchase } from "@/composables/usePurchase";
import { ProductsConnectionError } from "@/errors/productErrors";

export const useProductStore = defineStore("product", {
  state: () => {
    return {
      product: {},
      products: [],
      productsTicketRequire: [],
      productsRequired: [],
      productsTicketNoRequire: [],
      productsPurchase: [],
      formattedTickets: [],
      error: ""
    };
  },
  getters: {
    totalProductPrice: state => {
      return state.productsPurchase.reduce(
        (total, { commision, price, cant }) =>
          total + price * cant + (commision * cant || 0),
        0
      );
    },
    totalNoTaxesProductPrice: state => {
      return state.productsPurchase.reduce(
        (total, { price, cant }) => total + (price * cant || 0),
        0
      );
    },
    totalProductTaxes: state => {
      return state.productsPurchase.reduce(
        (total, { commision, cant }) => total + (commision * cant || 0),
        0
      );
    },
    purchaseProductsResume: state => {
      let existingItem = false;
      const repeatProducts = state.productsPurchase.reduce((acc, product) => {
        if (acc.length > 0) {
          existingItem = acc.find(prod => prod.id === product.id);
        }
        if (existingItem) {
          existingItem.cant += product.cant;
          existingItem.price = existingItem.price * existingItem.cant;
        } else {
          acc.push({ ...product });
        }
        return acc;
      }, []);
      return repeatProducts;
    }
  },
  actions: {
    async getProductsByTicket(params) {
      try {
        this.products = await ProductApi.getProductsByTicket(params);
        this.error = "";
      } catch (error) {
        this.error = error.message;
      }
    },
    productsNoRequireExist(product) {
      return (
        this.productsTicketNoRequire.some(
          prod => prod.product.id === product.id
        ) && !product.ticket_required
      );
    },
    clasifyProductsByTicketRequire() {
      for (const productIterator of this.products) {
        if (productIterator.product.enabled) {
          if (productIterator.product.ticket_required) {
            this.productsTicketRequire.push(productIterator);
          } else if (productIterator.product.product_required) {
            this.productsRequired.push(productIterator);
          } else if (!this.productsNoRequireExist(productIterator.product)) {
            this.productsTicketNoRequire.push(productIterator);
          }
        }
      }
    },
    resetProductsList() {
      this.productsTicketRequire = [];
      this.productsTicketNoRequire = [];
    },
    // Product:TODO: Revisar
    groupProductsByTicketTypes() {
      const productsTicketsId = {};
      for (const product of this.productsTicketRequire) {
        const tickettype = product.tickettype;
        if (!productsTicketsId[tickettype]) {
          productsTicketsId[tickettype] = [];
        }
        productsTicketsId[tickettype].push(product);
      }
      return productsTicketsId;
    },
    addProductsToTickets() {
      const { purchaseData } = usePurchase();
      const ticketsCopy = [...purchaseData.value?.tickets];
      let ticketsCopyFilter = [];
      const productsTicketsId = this.groupProductsByTicketTypes();

      for (const ticket of ticketsCopy) {
        if (productsTicketsId[ticket.ticket_type]) {
          const findTicket = ticketsCopy.find(
            ticketMap => ticketMap.id === ticket.id
          );
          findTicket.products = [];
          findTicket.products.push(productsTicketsId[ticket.ticket_type]);
        } else {
          ticketsCopyFilter = ticketsCopy.filter(
            obj => obj.ticket_type !== ticket.ticket_type
          );
        }
      }
      this.formattedTickets =
        ticketsCopyFilter.length > 0 ? ticketsCopyFilter : ticketsCopy;
    },
    // Purchase Product
    setProductsPurchase(product) {
      this.productsPurchase.push(product);
    },
    deleteProductPurchase(product) {
      this.productsPurchase.remove(prod => prod.id === product.id);
    },
    createProductPurchaseData(product) {
      const {
        id,
        cant: quantity,
        price,
        name,
        description,
        additional_data
      } = product;

      return {
        id,
        quantity,
        price,
        name,
        description,
        additional_data
      };
    }
  }
});
