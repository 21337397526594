<template>
  <div
    :class="{
      fadeIn: openModal,
      fadeOut: !openModal,
      center: position === 'center',
      fixed: position === 'fixed'
    }"
    class="modal-mask animate__animated overflow-scroll"
    @keydown.esc="emit('close')"
  >
    <div class="absolute right-4 z-10 top-4">
      <w-icon
        v-if="externalClose"
        size="20"
        class="cursor-pointer"
        :class="closeColor + ' ' + closePosition"
        :img="require('@/assets/images/modal-cross.png')"
        @click="emit('close')"
      />
    </div>
    <span
      v-if="isPreview"
      class="previous-preview-arrow"
      :class="previewIndex === 0 ? 'arrow-disabled' : ''"
      @click="prev"
      @touchstart="prev"
    >
      <w-icon
        size="80"
        class="cursor-pointer"
        :img="require('@/assets/images/ic-left-arrow-white.svg')"
      />
    </span>
    <div
      id="modalTemplate"
      ref="modal-template"
      :class="{
        successErrorDialog: successErrorDialog === 'success-error-dialog',
        fadeInDown: openModal,
        fadeOutUp: !openModal,
        small: size === 'small',
        'intermedium-small': size === 'intermedium-small',
        'normal-small': size === 'normal-small',
        intermedium: size === 'intermedium',
        'intermedium-large': size === 'intermedium-large',
        'intermedium-no-margin': size === 'intermedium-no-margin',
        none: size === 'none',
        normal: size === 'normal',
        large: size === 'large',
        complete: size === 'complete',
        'extra-large': size === 'extra-large',
        posR: !title,
        'overflow-hidden': overflowHidden,
        isPreview: 'md:h-screen'
      }"
      class="modal-template animate__animated animate__fadeInDown md:h-screen"
      :style="personalizedStyleContainer"
    >
      <div ref="modal" class="modal-container">
        <div
          class="modal-template-body relative"
          :class="isPreview ? 'preview-body' : ''"
        >
          <div
            v-if="!title && !isStatic && close && noArrow === false"
            class="absolute md:right-4 md:top-[0.2rem] z-10"
          >
            <w-icon
              size="40"
              class="cursor-pointer md:inline-block hidden"
              :img="
                require('@/assets/images/ic-cross-' +
                  closeColorComputed +
                  '.svg')
              "
              :class="closeColor + ' ' + closePosition"
              @click="emit('close')"
            />
            <w-icon
              size="30"
              class="cursor-pointer md:hidden inline-block"
              :img="
                require('@/assets/images/ic-cross-' +
                  closeColorComputed +
                  '.svg')
              "
              :class="closeColor + ' ' + closePosition"
              @click="emit('close')"
            />
          </div>
          <!-- <nuxt-link v-else-if="(!title && isStatic && close)"
                 to="/"
                 class="close-arrow"
                 :class="{'left': closePosition === 'left'}" /> -->
          <div v-else-if="title" class="modal-template-header">
            <h3>{{ title }}</h3>
            <nuxt-link
              v-if="isStatic && close"
              to="/"
              class="close-arrow"
              :class="{ left: closePosition === 'left' }"
              @click="emit('close')"
            />
            <span
              v-else-if="close"
              class="close-arrow"
              :class="{ left: closePosition === 'left' }"
              @click="emit('close')"
            />
            <slot name="header" />
          </div>
          <slot />
        </div>
      </div>
    </div>
    <span
      v-if="isPreview"
      class="next-preview-arrow"
      :class="lastIndex ? 'arrow-disabled' : ''"
      @click="next"
      @touchstart="next"
      ><w-icon
        size="80"
        class="cursor-pointer"
        :img="require('@/assets/images/ic-right-arrow-white.svg')"
    /></span>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed, toRefs } from "vue";
import useDetectOutsideClick from "@/plugins/useDetectOutsideClick";
import WIcon from "../ui/elements/WIcon.vue";
const props = defineProps({
  cancelOutsideClick: {
    type: Boolean,
    default: false
  },
  successErrorDialog: {
    type: String,
    default: ""
  },
  title: {
    type: String,
    default: ""
  },
  size: {
    type: String,
    default: "normal"
  },
  isStatic: {
    type: Boolean,
    default: false
  },
  close: {
    type: Boolean,
    default: true
  },
  externalClose: {
    type: Boolean,
    default: false
  },
  closePosition: {
    type: String,
    default: "right"
  },
  noArrow: {
    type: Boolean,
    required: false,
    default: false
  },
  closeColor: {
    type: String,
    default: ""
  },
  position: {
    type: String,
    default: "fixed"
  },
  overflowHidden: {
    type: Boolean,
    default: false
  },
  previewIndex: {
    type: Number,
    default: 0
  },
  lastIndex: {
    type: Boolean,
    default: false
  },
  isPreview: {
    type: Boolean,
    default: false
  },
  personalizedStyleContainer: {
    type: Object,
    default: () => {
      return {};
    }
  }
});
const { cancelOutsideClick } = toRefs(props);
const emit = defineEmits(["close", "next", "previous"]);

const openModal = ref(true);
const modal = ref(null);
const isOpen = ref(false);

const closeColorComputed = computed(() => {
  if (props.personalizedStyleContainer.backgroundColor) {
    let hex = props.personalizedStyleContainer.backgroundColor;
    // Eliminar el carácter '#' si está presente
    if (hex.startsWith("#")) {
      hex = hex.slice(1);
    }

    // Convertir un color hexadecimal de 3 caracteres a 6 caracteres
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map(char => char + char)
        .join("");
    }

    // Extraer los valores RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Calcular el brillo usando la fórmula mencionada
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    // Determinar el color del texto basado en el brillo
    return brightness > 186 ? "black" : "white";
  } else {
    return "white";
  }
});

const handleEsc = e => e.keyCode === 27 && emit("close");
const next = event => {
  event.stopPropagation();
  emit("next");
};
const prev = event => {
  event.stopPropagation();
  if (props.previewIndex > 0) {
    emit("previous");
  }
};

onMounted(() => setTimeout(() => (isOpen.value = true), 100));
onUnmounted(() => {
  document.getElementsByTagName("body")[0].classList.remove("no-scroll");
  window.removeEventListener("keyup", handleEsc);
});

useDetectOutsideClick(
  modal,
  () =>
    !cancelOutsideClick.value &&
    isOpen.value &&
    !props.isPreview &&
    emit("close")
);

const init = () => {
  let body = document.getElementsByTagName("body")[0];
  body.className += " no-scroll";
  if (props.close) {
    window.addEventListener("keyup", handleEsc);
  }
};

init();
</script>

<style lang="scss">
@import "./modal-template.scss";
.container-access-code .modal-mask .modal-template.intermedium {
  padding: 0px !important;
}
body.no-scroll {
  overflow: hidden;
}
.close-arrow.black::before {
  color: black !important;
}
.modal-template-header {
  background-color: $inputLigthBackground !important;
  border-radius: 0 !important;
}
.external-close {
  right: 15px;
  top: 9px;
  display: block;
  width: 25px;
  font-size: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
}
.previous-preview-arrow,
.next-preview-arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  font-size: 80px;
  z-index: 11;
  @media screen and (max-width: $screen-md) {
    font-size: 50px;
    top: 44%;
  }
}
.previous-preview-arrow {
  left: 10%;
  @media screen and (max-width: $screen-lg) {
    left: 2%;
  }
}
.next-preview-arrow {
  right: 10%;
  @media screen and (max-width: $screen-lg) {
    right: 2%;
  }
}
.arrow-disabled {
  // color: grey;
  pointer-events: none; /* Deshabilita los eventos de puntero (como clics) */
  opacity: 0.6;
}
.preview-body {
  display: flex;
  align-items: center;
}
.modal-template-body {
  position: relative !important;
  min-height: 135px;
}
.modal-mask {
  display: flex;
}
</style>
