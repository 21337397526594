import { storeToRefs } from "pinia";
import { formatCurrency } from "@/plugins/currency";
import {
  CHANNEL_ID_HERBALIFE,
  INSURANCE_MANDATORY,
  PURCHASE_STARTED
} from "@/constants.js";

import { usePurchaseStore } from "@/stores/modules/purchase";
import { useEventStore } from "@/stores/modules/event";
import { useEvent } from "@/composables/useEvent";
import { useUserStore } from "@/stores/modules/user";
import { useUser } from "@/composables/useUser";
import { usePaymentStore } from "@/stores/modules/payment";
import { useRoute, useRouter } from "vue-router";
import { useModalHandler } from "@/plugins/useModalHandler";

import { computed } from "vue";
import { useWaitingRoom } from "@/composables/useWaitingRoom";
import { VueCookieNext } from "vue-cookie-next";

export const usePurchase = () => {
  const $cookie = VueCookieNext;
  const route = useRoute();
  const router = useRouter();
  const purchaseStore = usePurchaseStore();
  const {
    actualPurchase,
    purchaseData,
    bannerAd,
    nominativeData,
    validator,
    newsletter,
    termsAccepted,
    purchaseExtraData,
    accessCodeValid,
    accessCode,
    maxNumberTicketByCode,
    seatsioData,
    referenceParams,
    isWhiteLabel,
    totalPrice
  } = storeToRefs(purchaseStore);
  const eventStore = useEventStore();
  const { details, tickets, configSeatsio } = storeToRefs(eventStore);
  const { isIframe } = useEvent();
  const userStore = useUserStore();
  const { myTickets, userStatus, buyer } = storeToRefs(userStore);
  const paymentStore = usePaymentStore();
  const { token, tokenInfo, isWaitingRoom } = useWaitingRoom();
  const { isAuth, deleteCookies } = useUser();

  const createNewPurchase = () => {
    purchaseStore.createNewPurchase();
  };

  const addTicket = async ticketId => {
    let selectedTicket = actualPurchase.value.selectedTickets.find(
      ticket => ticket.id === ticketId
    );

    let quantity = selectedTicket.pair ? 2 : 1;

    if (
      selectedTicket.selecteds === 0 &&
      (selectedTicket.selecteds + (selectedTicket.min_tickets || 1) <=
        selectedTicket.remaining ||
        selectedTicket.selecteds + (selectedTicket.min_tickets || 1) <=
          selectedTicket.available)
    ) {
      selectedTicket.selecteds += selectedTicket.min_tickets || 1;
    } else if (
      (selectedTicket.selecteds + quantity <= selectedTicket.remaining ||
        selectedTicket.selecteds + quantity <= selectedTicket.available) &&
      selectedTicket.selecteds + quantity <= selectedTicket.max_tickets
    ) {
      selectedTicket.selecteds += quantity;
    }
  };

  const addTicketWeswap = ticket => {
    actualPurchase.value.selectedTickets = [
      { ...ticket, selecteds: 1, f2f: true }
    ];
    details.value.weswapPurchase = true;
  };

  const minusTicket = ticketId => {
    let selectedTicket = actualPurchase.value.selectedTickets.find(
      ticket => ticket.id === ticketId
    );
    let quantity = selectedTicket.pair ? 2 : 1;
    if (selectedTicket.selecteds - quantity < selectedTicket.min_tickets) {
      selectedTicket.selecteds = 0;
    } else if (selectedTicket.selecteds - quantity >= 0) {
      selectedTicket.selecteds -= quantity;
    }
  };

  const createPurchase = async (initByModal = false) => {
    const {
      SIGN_IN_PURCHASE,
      BUYER_MODAL,
      BASIC_INFO_MODAL,
      openModal
    } = useModalHandler();
    // Herbalife
    if (details.value.channel?.channel === CHANNEL_ID_HERBALIFE) {
      makeHerbalifePurchase();
    } else if (
      (isWaitingRoom.value || isIframe.value) &&
      !isAuth.value &&
      !initByModal
    ) {
      openModal(BUYER_MODAL);
      // TODO: comprobar si esta en iframe y sacar el openBuyerInfo
    } else if (
      (!isWaitingRoom.value || !isIframe.value) &&
      !isAuth.value &&
      !initByModal
    ) {
      openModal(SIGN_IN_PURCHASE);
    } else {
      if (!initByModal && (!isIframe.value || !isWaitingRoom.value)) {
        await userStore.getUserStatus();
        if (!userStatus.value.name || !userStatus.value.profile) {
          openModal(BASIC_INFO_MODAL);
          return;
        }
      }
      if (isIframe.value || isWaitingRoom.value) {
        await initPurchase(buyer.value);
      } else {
        await initPurchase();
      }
      trackFbEvents(details.value, purchaseData.value);
      // TODO: Comporbar que no hay errores en la purchase
      if (purchaseData.value) {
        const isF2F = actualPurchase.value.selectedTickets.some(
          tt => tt.selecteds > 0 && tt.f2f === true
        );
        // Waiting Room
        if (route.name === "WaitingRoomPurchasePage") {
          router.push({
            name: "WaitingRoomPaymentPage",
            params: { reference: purchaseData.value.reference },
            query: { f2f: isF2F },
            ...referenceParams.value
          });
          // Widget
        } else if (isIframe.value) {
          if (purchaseData.value.has_cross_sell) {
            router.push({
              name: "PurchaseCrossingPageWidget",
              params: { reference: purchaseData.value.reference },
              query: { f2f: isF2F },
              ...referenceParams.value
            });
          } else {
            router.push({
              name: "PaymentPageWidget",
              params: { reference: purchaseData.value.reference },
              query: { f2f: isF2F },
              ...referenceParams.value
            });
          }
          // Taquilla
        } else if (route.name === "PurchaseOfficePage") {
          router.push({
            name: "PaymentOfficePage",
            params: { reference: purchaseData.value.reference },
            query: { f2f: isF2F },
            ...referenceParams.value
          });
          // Venta cruzada
        } else if (purchaseData.value.has_cross_sell) {
          router.push({
            name: "PurchaseCrossingPage",
            params: { reference: purchaseData.value.reference },
            query: { f2f: isF2F },
            ...referenceParams.value
          });
        } else {
          router.push({
            name: "PaymentPage",
            params: { reference: purchaseData.value.reference },
            query: { f2f: isF2F },
            ...referenceParams.value
          });
        }
      }
    }
  };
  const createProductPurchase = async () => {
    await purchaseStore.createProductPurchase();
    const isF2F = actualPurchase.value.selectedTickets.some(
      tt => tt.selecteds > 0 && tt.f2f === true
    );
    router.push({
      name: "PaymentPage",
      params: { reference: purchaseData.value.reference },
      query: { f2f: isF2F },
      ...referenceParams.value
    });
  };

  const makeHerbalifePurchase = async () => {
    const { openErrorModal } = useModalHandler();
    try {
      if (isAuth.value) {
        await deleteCookies();
        await userStore.closeSession();
      }
      let herbalifeBuyer = {
        first_name: accessCode.value,
        last_name: "Herbalife",
        email: "ticketing-anonymous+herbalife@wegow.com",
        privacy_policy: true
      };
      await userStore.sendBuyer(herbalifeBuyer);
      await initPurchase(buyer.value);
      const paymentDataPayload = {
        details: {
          type: "free"
        },
        event_id: purchaseData.value.event.id,
        purchase: purchaseData.value.id,
        discount_code: purchaseData.value.discount_code,
        promoter_terms: true
      };
      await paymentStore.createPayments(paymentDataPayload);
      window.location.href = `https://herbalife.bracelit.es/access?memberId=${accessCode.value}`;
    } catch (error) {
      openErrorModal({
        modal: {
          title: "Error al hacer la compra con Herbalife, vuelva a intentarlo.",
          btn_message: "wegow.error.accept"
        }
      });
    }
  };

  const trackFbEvents = async (event, purchase) => {
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "InitiateCheckout", {
        content_name: event.title
      });
    }
    if (typeof window.fbq !== "undefined") {
      const content_ids = purchase.tickets.map(item => {
        return item.id;
      });
      const contents = purchase.tickets.map(item => {
        return {
          id: item.id,
          quantity: item.quantity,
          item_price: item.price
        };
      });
      window.fbq("track", "AddToCart", {
        content_ids: content_ids,
        value: purchase.total_price,
        currency: purchase.currency,
        content_type: "product",
        contents: contents
      });
    }
  };

  const setReferenceParams = params => {
    purchaseStore.setReference(params);
  };

  const initPurchase = async purchaseBuyer => {
    // TODO: revisar y eliminar: promoter_terms: true
    let purchase = {};
    if (isWaitingRoom.value) {
      if (tokenInfo.value.purchase_status === PURCHASE_STARTED)
        return router.push({
          name: "WaitingRoom",
          params: { slug: route.params.slug }
        });
      purchase.waiting_room_access_token =
        token.value || $cookie.getCookie("wegow.wr-token");
    }
    if (purchaseBuyer && purchaseBuyer.id) {
      purchase.buyer = purchaseBuyer.id;
      purchase.promoter_terms = true;
    }
    if (accessCode.value) {
      purchase.access_code = accessCode.value;
    }
    if (route.name === "PurchaseOfficePage") {
      purchase.ticket_office = true;
    }
    if (isWhiteLabel.value) {
      purchase.hb = isWhiteLabel.value;
    }

    let tickets = [];
    if (seatsioData.value?.ticket_dist) {
      purchase = { ...purchase, ...seatsioData.value };
      await purchaseStore.createSeatsioPurchase(purchase);
    } else if (!details.value.weswapPurchase) {
      actualPurchase.value.selectedTickets.forEach(ticket => {
        for (let i = 0; i < ticket.selecteds; i++) {
          tickets.push({
            ticket_type: ticket.id
          });
        }
      });
      purchase.tickets = tickets;
      await purchaseStore.createPurchase(purchase);
    } else {
      actualPurchase.value.selectedTickets.forEach(ticket => {
        for (let i = 0; i < ticket.selecteds; i++) {
          tickets.push({
            ticket_transfer_tier: ticket.id
          });
        }
      });
      purchase.tickets = tickets;
      await purchaseStore.createTransfer(purchase);
    }
  };

  const setSeatsioData = tickets => {
    let payload = {
      hold_token: configSeatsio.value.hold_token,
      ticket_dist: details.value.ticket_distributions[0].id,
      tickets: tickets
    };
    purchaseStore.setSeatsioData(payload);
  };

  const cleanCategoryLabel = () => {
    tickets.value.forEach(item => {
      delete item.category_label;
    });
  };

  const validateAccessCode = async ac => {
    let payload = {
      slug: details.value.slug,
      data: {
        code: ac
      }
    };
    let res = await purchaseStore.validateAccessCode(payload);
    return res;
  };

  const setReference = async params => {
    await purchaseStore.setReference(params);
  };

  const setWhiteLabel = data => {
    isWhiteLabel.value = data;
  };

  const getThankAd = async params => {
    await purchaseStore.getThankAd(params);
  };

  const getPurchase = async params => {
    await purchaseStore.getPurchase(params);
  };

  const updatePurchase = async () => {
    purchaseExtraData.value = {};
    let emails = [];
    let personal_ids = [];
    for (const ticket of purchaseData.value.tickets.filter(ticket => {
      return (
        ticket.personal ||
        ticket.personal_id ||
        ticket.personal_birthday ||
        ticket.personal_email ||
        ticket.personal_phone
      );
    })) {
      let ticketToSend = { id: ticket.id };
      // Name and Surname
      if (
        ticket.personal &&
        (!nominativeData.value[ticket.id].name ||
          !nominativeData.value[ticket.id].surname ||
          !validator.value[ticket.id].name ||
          !validator.value[ticket.id].surname)
      ) {
        return "wegow.payment.nominativeError";
      } else if (ticket.personal) {
        ticketToSend.first_name = nominativeData.value[ticket.id].name;
        ticketToSend.last_name = nominativeData.value[ticket.id].surname;
      }
      // Email
      if (
        ticket.personal_email &&
        (!nominativeData.value[ticket.id].email ||
          !validator.value[ticket.id].email)
      ) {
        return "wegow.payment.nominativeError";
      } else if (ticket.personal_email) {
        // TODO: revisar que el campo se llama asi
        ticketToSend.email = nominativeData.value[ticket.id].email;
        if (ticket.email_uniqueness) {
          emails.push(nominativeData.value[ticket.id].email.toLowerCase());
          if (!ticketToSend.first_name) {
            ticketToSend.first_name = "";
            ticketToSend.last_name = "";
          }
        }
      }
      // Phone
      if (
        ticket.personal_phone &&
        (!nominativeData.value[ticket.id].phone ||
          !nominativeData.value[ticket.id].phone.country ||
          !nominativeData.value[ticket.id].phone.phone)
      ) {
        return "wegow.payment.nominativeError";
      } else if (ticket.personal_phone) {
        ticketToSend.phone = nominativeData.value[ticket.id].phone.phone;
        ticketToSend.phone_cou_code =
          nominativeData.value[ticket.id].phone.country.iso2;
        ticketToSend.phone_prefix =
          nominativeData.value[ticket.id].phone.country.dialCode;
      }

      // Personal id(DNI)
      if (
        ticket.personal_id &&
        (!nominativeData.value[ticket.id].dni ||
          !validator.value[ticket.id].dni)
      ) {
        return "wegow.payment.nominativeError";
      } else if (ticket.personal_id) {
        ticketToSend.id_card = nominativeData.value[ticket.id].dni;
        if (ticket.ids_uniqueness) {
          personal_ids.push(ticketToSend.id_card);
        }
      }
      // Address
      if (
        ticket.personal_address &&
        (!nominativeData.value[ticket.id].personal_address ||
          !validator.value[ticket.id].personal_address)
      ) {
        return "wegow.payment.nominativeError";
      } else if (ticket.personal_address) {
        ticketToSend.address = nominativeData.value[ticket.id].personal_address;
      }
      // Gender
      if (
        ticket.personal_gender &&
        !nominativeData.value[ticket.id].personal_gender
      ) {
        return "wegow.payment.nominativeError";
      } else if (ticket.personal_gender) {
        ticketToSend.gender = nominativeData.value[ticket.id].personal_gender;
      }
      // Birthday
      if (
        ticket.personal_birthday &&
        (!nominativeData.value[ticket.id].personal_birthday ||
          !validator.value[ticket.id].personal_birthday)
      ) {
        return "wegow.payment.nominativeError";
      } else if (ticket.personal_birthday) {
        ticketToSend.birthday = nominativeData.value[
          ticket.id
        ].personal_birthday.replace(/-/g, "/");
      }
      if (ticket.personal_address) {
        if (
          !nominativeData.value[ticket.id].locality ||
          !validator.value[ticket.id].locality
        ) {
          return "wegow.payment.nominativeError";
        } else {
          ticketToSend.locality = nominativeData.value[ticket.id].locality;
        }
        // State
        if (
          !nominativeData.value[ticket.id].state ||
          !validator.value[ticket.id].state
        ) {
          return "wegow.payment.nominativeError";
        } else {
          ticketToSend.state = nominativeData.value[ticket.id].state;
        }
        // Country
        if (!nominativeData.value[ticket.id].country) {
          return "wegow.payment.nominativeError";
        } else {
          ticketToSend.country = nominativeData.value[ticket.id].country;
        }
        // Postal Code
        if (
          !nominativeData.value[ticket.id].postal_code ||
          !validator.value[ticket.id].postal_code
        ) {
          return "wegow.payment.nominativeError";
        } else {
          ticketToSend.postal_code =
            nominativeData.value[ticket.id].postal_code;
        }
      }
      // Locality

      if (!purchaseExtraData.value.tickets) {
        purchaseExtraData.value.tickets = [ticketToSend];
      } else {
        purchaseExtraData.value.tickets.push(ticketToSend);
      }
    }
    if (emails.length || personal_ids.length) {
      if (new Set(emails).size !== emails.length) {
        return "wegow.payment.uniqueEmailError";
      } else if (new Set(personal_ids).size !== personal_ids.length) {
        return "wegow.payment.uniqueDNIError";
      } else if (
        !(await purchaseStore.checkIfEmailIsUnique({
          id: purchaseData.value.event.id,
          emails: emails,
          id_numbers: personal_ids
        }))
      ) {
        if (emails.length && personal_ids.length) {
          return "wegow.payment.uniqueEmailDNIPurchaseError";
        } else if (!personal_ids.length) {
          return "wegow.payment.uniqueEmailPurchaseError";
        } else {
          return "wegow.payment.uniqueDNIPurchaseError";
        }
      }
    }
    if (
      route.name !== "PaymentOfficePage" &&
      details.value.promoter_terms_option &&
      !termsAccepted.value
    ) {
      return "wegow.payment.promoterTermsError";
    } else {
      purchaseExtraData.value.promoter_terms = true;
    }
    if (
      route.name !== "PaymentOfficePage" &&
      details.value.promoter_newsletter_option &&
      newsletter.value
    ) {
      purchaseExtraData.value.promoter_newsletter = true;
    }
    return true;
  };

  // WeSwap
  const sellTicket = async (ticket, active) => {
    let payload = {
      tickets_ids: [ticket.id],
      active: active
    };
    let response = await purchaseStore.editWeswapTicket(payload);
    if (response.errors[0]) {
      // TODO: mostrar error
    } else {
      myTickets.value = myTickets.value.map(ticketObj => {
        if (ticketObj.id === response.tickets[0].id) {
          return {
            ...ticketObj,
            ...response.tickets[0]
          };
        } else {
          return ticketObj;
        }
      });
    }
  };

  const isValidCif = cif => {
    if (!cif || cif.length !== 9) {
      return false;
    }

    let letters = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
    let digits = cif.substr(1, cif.length - 2);
    let letter = cif.substr(0, 1);
    let control = cif.substr(cif.length - 1);
    let sum = 0;
    let i;
    let digit;

    if (!letter.match(/[A-Z]/)) {
      return false;
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) {
        return false;
      }

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = parseInt(digit / 10) + (digit % 10);
        }

        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
  };

  const isValidNif = dni => {
    let numero;
    let letr;
    let letra;
    let expresion_regular_dni;

    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

    if (expresion_regular_dni.test(dni) === true) {
      numero = dni.substr(0, dni.length - 1);
      letr = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = "TRWAGMYFPDXBNJZSQVHLCKET";
      letra = letra.substring(numero, numero + 1);
      if (letra !== letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isValidNie = nie => {
    const nieRegex = /^[XYZ]\d{7}[A-Z]$/;

    if (!nieRegex.test(nie)) {
      return false;
    }

    let niePrefix = {
      X: "0",
      Y: "1",
      Z: "2"
    };

    let nieNumber = nie.substr(1, 7);
    let controlLetter = nie.substr(8, 1).toUpperCase();
    let fullNieNumber = niePrefix[nie[0]] + nieNumber;
    let validControlLetter = "TRWAGMYFPDXBNJZSQVHLCKET"[fullNieNumber % 23];

    return controlLetter === validControlLetter;
  };

  const isValidPassport = passport => {
    const passportRegex = /^[A-Z0-9]{5,9}$/;
    return passportRegex.test(passport);
  };

  const resetPurchase = () => {
    tickets.value = [];
    actualPurchase.value = {};
    isIframe.value = false;
    myTickets.value = [];
    buyer.value = {};
  };

  return {
    actualPurchase,
    purchaseData,
    bannerAd,
    nominativeData,
    validator,
    newsletter,
    termsAccepted,
    purchaseExtraData,
    accessCodeValid,
    maxNumberTicketByCode,
    seatsioData,
    referenceParams,
    isWhiteLabel,
    // Getters
    totalPrice,

    resetPurchase,
    isValidCif,
    isValidNif,
    isValidNie,
    isValidPassport,

    createNewPurchase,
    validateAccessCode,
    createPurchase,
    createProductPurchase,
    getPurchase,
    getThankAd,
    setSeatsioData,
    cleanCategoryLabel,
    addTicketWeswap,

    addTicket,
    minusTicket,
    updatePurchase,
    setReference,
    setReferenceParams,
    setWhiteLabel,

    // WeSwap
    sellTicket
  };
};
